<script>
import { ValidationObserver } from 'vee-validate'
import modal from '/~/core/mdl'
import { useTravel } from '/~/extensions/travel/composables'
import { createDate } from '/~/utils/format/date'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseDatepicker from '/~/components/base/datepicker/base-datepicker.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseSelect from '/~/components/base/select/base-select.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useForm } from '/~/composables/base/use-form'
import { useUser } from '/~/composables/user'

export default {
  name: 'travel-details-form-modal',
  components: {
    BaseDatepicker,
    BaseInput,
    BaseButton,
    BaseCheckbox,
    BaseMdl,
    BaseSelect,
    ValidationObserver,
  },
  props: {
    packageDetails: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { user } = useUser()
    const { enquireAboutPackage } = useTravel()
    const { validationObserverRef } = useForm()

    return {
      user,
      sendForm: enquireAboutPackage,
      validationObserverRef,
    }
  },
  data() {
    return {
      form: {},
      submitting: false,
      backendErrors: {},
      departureCities: [
        { text: 'Adelaide', value: 'adelaide' },
        { text: 'Sydney', value: 'sydney' },
        { text: 'Gold Coast', value: 'gold-coast' },
        { text: 'Melbourne', value: 'melbourne' },
        { text: 'Perth', value: 'perth' },
      ],
      departureDisabledDates: {
        to: createDate(new Date()).toDate(),
      },
      birthdayDisabledDates: {
        from: createDate(new Date()).toDate(),
      },
      timeToCallOpts: [
        { text: 'Any', value: 'any' },
        { text: 'Morning', value: 'morning' },
        { text: 'Afternoon', value: 'afternoon' },
      ],
    }
  },
  computed: {
    adultsNum() {
      const peopleNumList = []

      for (let i = 1; i <= 12; i++) {
        peopleNumList.push({ text: i, value: i })
      }

      return peopleNumList
    },
    childrenNum() {
      const peopleNumList = []

      for (let i = 0; i <= 12; i++) {
        peopleNumList.push({ text: i, value: i })
      }

      return peopleNumList
    },
    package() {
      return {
        name: this.packageDetails.name,
        id: this.slug,
      }
    },
  },
  created() {
    const { user } = this

    this.form = {
      first_name: user.firstName || '',
      last_name: user.lastName || '',
      email: user.email || '',
      mobile: user.mobile || '',
      departure_date: '',
      departure_city: '',
      number_of_adults: 1,
      number_of_children: 0,
      children_date_of_birth: [],
      call_time: '',
      notes: '',
      disable_comms: '1',
      advertised_price: this.packageDetails.price.valueFrom,
      brand_id: '49',
      categories: 'Escapes',
      division_id: '1',
      page_url: this.packageDetails.link,
      source: 'EonX',
    }
  },
  methods: {
    async submitForm() {
      const { form } = this

      this.submitting = true
      this.backendErrors = {}

      const params = {
        ...form,
        mobile: form.mobile ? form.mobile.replace(/\s/g, '') : null,
        package: this.package,
      }

      return this.sendForm(params)
        .then(({ trackingId: [trackingId] }) => {
          this.submitting = false

          modal.hide()

          this.$emit('form-sent', {
            trackingId,
            name: form.first_name,
          })
        })
        .catch((e) => {
          const { response } = e

          if (response && response.data) {
            const { errors } = response.data

            for (const key in errors) {
              if (Object.prototype.hasOwnProperty.call(errors, key)) {
                errors[key] = errors[key].join('\n')
              }
            }

            this.backendErrors = errors
          } else {
            console.error(e)
          }

          this.submitting = false
        })
    },
    onChildrenInput(val) {
      const { form } = this
      const parsedNum = parseInt(val, 10) || 0
      const newDOBs = []

      for (let i = 0; i < parsedNum; i++) {
        newDOBs.push('')
      }

      form.children_date_of_birth = newDOBs
      form.number_of_children = parsedNum > 0 ? parsedNum : val
    },
  },
}
</script>

<template>
  <base-mdl
    class="travelp-enquire-modal"
    title="Travel Enquiry"
    fullscreen="mobile"
  >
    <validation-observer
      v-slot="{ handleSubmit }"
      ref="validationObserverRef"
      slim
    >
      <form
        ref="form"
        :disabled="submitting"
        @submit.prevent="handleSubmit(submitForm)"
      >
        <div class="mb-[30px] font-bold">
          <span v-html="packageDetails.destination" />
        </div>
        <div class="grid gap-x-5 sm:grid-cols-2">
          <base-input
            v-model="form.first_name"
            :validation="{
              rules: 'required',
              name: 'First Name',
            }"
            :disabled="submitting"
            :error="backendErrors.first_name"
            label="First Name"
            required
            name="First Name"
          />
          <base-input
            v-model="form.last_name"
            :validation="{
              rules: 'required',
              name: 'Last Name',
            }"
            :error="backendErrors.last_name"
            label="Last Name"
            :disabled="submitting"
            required
            name="Last Name"
          />
          <base-input
            v-model="form.email"
            :validation="{
              rules: 'required|email',
              name: 'Email',
            }"
            :error="backendErrors.email"
            label="Email"
            :disabled="submitting"
            required
            name="Email"
            type="email"
          />
          <base-input
            v-model="form.mobile"
            :validation="{
              rules: 'required|mobile',
              name: 'Mobile',
            }"
            :disabled="submitting"
            mask="mobile"
            :error="backendErrors.mobile"
            label="Mobile"
            required
            name="Mobile"
            type="tel"
          />
          <div class="mb-5">
            <base-datepicker
              v-model="form.departure_date"
              :validation="{
                rules: 'required',
                name: 'Departure Date',
                mode: 'aggressive',
              }"
              :error="backendErrors.departure_date"
              :disabled-dates="
                departureDisabledDates || backendErrors.departure_date
              "
              :disabled="submitting"
              placeholder="Departure Date"
              required
              format="daymonthyear"
              label="Departure Date"
              name="Departure Date"
              :min-date-mobile="new Date().toISOString().split('T')[0]"
              :popup-style="{
                zIndex: 999,
              }"
            />
          </div>
          <base-select
            v-model="form.departure_city"
            :validation="{
              rules: 'required',
              name: 'Departure City',
            }"
            :error="backendErrors.departure_city"
            :options="departureCities"
            fullwidth-popup
            :disabled="submitting"
            label="Departure City"
            required
            name="Departure City"
          />
          <base-select
            v-model="form.number_of_adults"
            :validation="{
              rules: 'required',
              name: 'Number of adults',
            }"
            :disabled="submitting"
            :options="adultsNum"
            :error="backendErrors.number_of_adults"
            label="Number of adults"
            required
            name="Number of adults"
          />
          <base-select
            :validation="{
              rules: 'required',
              name: 'Number of children',
            }"
            :options="childrenNum"
            :value="form.number_of_children"
            :disabled="submitting"
            :error="backendErrors.number_of_children"
            label="Number of children"
            required
            name="Number of children"
            @input="onChildrenInput"
          />
          <div
            v-for="(_, index) in form.children_date_of_birth"
            :key="index"
            class="mb-5"
          >
            <base-datepicker
              v-model="form.children_date_of_birth[index]"
              :validation="{
                rules: 'required',
                name: `Children #${index + 1} Date of Birth`,
                vid: `Children #${index + 1} Date of Birth`,
                mode: 'aggressive',
              }"
              :disabled="submitting"
              :label="`Children #${index + 1} Date of Birth *`"
              :name="`Children #${index + 1} Date of Birth`"
              :disabled-dates="birthdayDisabledDates"
              format="daymonthyear"
              :max-date-mobile="new Date().toISOString().split('T')[0]"
              :popup-style="{
                zIndex: 999,
              }"
            />
          </div>
          <base-select
            v-model="form.call_time"
            :validation="{
              rules: 'required',
              name: 'Best time to call',
            }"
            :disabled="submitting"
            :options="timeToCallOpts"
            :error="backendErrors.call_time"
            fullwidth-popup
            label="Best time to call"
            required
            name="Best time to call"
          />
          <base-input
            v-model="form.notes"
            :validation="{
              rules: 'max:500',
              name: 'Comments',
            }"
            :disabled="submitting"
            :error="backendErrors.notes"
            label="Comments"
            name="Notes"
          />
        </div>
        <base-checkbox
          :value="form.disable_comms"
          :disabled="submitting"
          @change="form.disable_comms = Number(!$event).toString()"
        >
          I would like to be informed about offers and other services
        </base-checkbox>
        <div class="mt-4 flex items-center">
          <base-button
            ref="submit"
            :disabled="submitting"
            :loading="submitting"
            type="submit"
            class="ml-auto w-full sm:w-auto"
          >
            Submit
          </base-button>
        </div>
      </form>
    </validation-observer>
  </base-mdl>
</template>
